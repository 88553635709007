<template>
	<v-dialog max-width="300px" v-model="model">
		<v-card
			min-height="250px"
			class=" d-flex flex-column justify-space-around  align-center"
		>
			<BtnClose @click="close" />

			<v-card-title class="mt-2">
				<v-icon color="red" class="material-icons-outlined" size="60"
					>cancel</v-icon
				>
			</v-card-title>
			<v-container>
				<div class="font-weight-bold text-center">{{ errorText }}</div>
			</v-container>
			<ShuttleCentralContact />
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
import ShuttleCentralContact from "@/components/ShuttleCentralContact";
export default {
	components: {
		ShuttleCentralContact,
		BtnClose
	},
	props: {
		value: Boolean,
		errorType: String
	},
	data: () => ({
		style: "font-weight-medium text-decoration-underline primary--text mt-3"
	}),
	computed: {
    model: {
      get(){
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
		errorText: vm =>
			vm.errorType
				? vm.t(`location.${vm.errorType}`)
				: vm.t("location.text")
	},
	methods: {
		close() {
			this.model = false
		},
		t(key, options = {}) {
			return this.$t(`info.${key}`, options);
		}
	}
};
</script>
