<template>
	<div>
		<v-autocomplete
			full-width
			:search-input.sync="search"
			class="rounded-lg"
			clearable
			:items="items"
			:readonly="disabled"
			:loading="loading"
			:placeholder="placeholder"
			:value="value"
			item-value="ref"
			no-filter
			return-object
			item-text="name"
			dense
			outlined
			@input="placeValidate($event)"
		>
			<template v-slot:item="data">
				<v-list class="pa-0">
					<v-list-item three-line>
						<v-list-item-avatar class="ml-n6 mr-1">
							<v-icon
								color="primary"
								class="material-icons-outlined"
								>{{
									data.item.isAirport
										? "flight"
										: "location_on"
								}}</v-icon
							>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title
								v-html="data.item.name"
							></v-list-item-title>
							<v-list-item-subtitle
								v-html="data.item.address"
							></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
			<template v-slot:prepend-inner>
				<v-icon class="material-icons-outlined">search</v-icon>
			</template>
			<template v-slot:append>
				<v-icon></v-icon>
			</template>
		</v-autocomplete>
		<DialogLocationInvalid
			:error-type="error.type"
			v-model="error.dialog"
		/>
	</div>
</template>

<script>
import { api } from "@/service/api.service";
import { debounce } from "debounce";
import DialogLocationInvalid from "@/components/dialog/DialogLocationInvalid";
export default {
	components: {
		DialogLocationInvalid
	},
	props: {
		value: [String, Object, Number],
		placeholder: String,
		disabled: Boolean,
		oldRef: String,
		nearby: Object
	},
	data: () => ({
		search: null,
		loading: false,
		error: {
			dialog: false,
			type: null
		},
		items: []
	}),
	watch: {
		search: debounce(function(v) {
			if (v) if (v.length > 0) this.searchLocation(v);
		}, 500)
	},
	created() {
		if (this.value) this.items = [this.value];
	},
	methods: {
		async searchLocation(term) {
			this.loading = true;
			const nearbyLocationLat = this.nearby?.latitude || "";
			const nearbyLocationLng = this.nearby?.longitude || "";
			try {
				const response = await api.get("/travelers/places/search", {
					params: { term, nearbyLocationLat, nearbyLocationLng }
				});
				this.items = JSON.parse(JSON.stringify(response.data));
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},
		async placeValidate(selected) {
			if (!selected) return;
			await api
				.post(`/travelers/places/validate`, {
					data: { place1: this.oldRef, place2: selected.ref },
					showError: false
				})
				.then(({ data }) => {
					if (data?.validZone) return this.setLocation(selected);
				})
				.catch(({ data }) => {
					if (data?.codeError) {
						this.error.dialog = true;
						this.error.type = data.codeError;
					} else {
						this.error.dialog = true;
						this.error.type = null;
					}
				})
				.finally(() => {
					this.search = null;
				});
		},
		async setLocation({ ref }) {
			if (ref) {
				try {
					const response = await api.get(
						`/travelers/places/${ref}`,
						{}
					);
					const selected = {
						...response.data,
						ref: ref
					};
					this.$emit("input", selected);
					this.$emit("change");
				} catch (error) {
					this.error.dialog = true;
					this.error.type = null;
					console.log(error);
				}
			}
		}
	}
};
</script>
