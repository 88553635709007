<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container>
			<v-form ref="form">
				<v-row dense class="mt-5">
					<v-col cols="12">
						<SelectLocation
							:old-ref="oldRef"
							:nearby="nearby"
							:disabled="inputDisable"
							:placeholder="placeholder"
							@change="selected"
							v-model="selection"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-container>
	</div>
</template>

<script>
import SelectLocation from "@/components/field/SelectLocation";
import { mapFields } from "@/helpers/mapFields";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
	components: {
		SelectLocation
	},
	data: () => ({
		selection: null
	}),

	computed: {
		...mapGetters("service", ["getBasePath", "inputDisable"]),
		...mapState("service", ["checkinModel"]),
		...mapFields({
			fields: ["pickup", "dropoff", "flightValidationData"],
			base: "auxModel",
			mutation: "SET_UPDATE_AUXMODEL",
			module: "service"
		}),
		isPickup: vm => vm.$route.params.id == "pickup",
		oldRef: vm => (vm.isPickup ? vm.pickupRef : vm.dropoffRef),
		placeholder: vm =>
			vm.isPickup ? vm.t("pickup.label") : vm.t("dropOff.label"),
		nearby: vm => (vm.isPickup ? vm.dropoff : vm.pickup),
		pickupRef: vm =>
			vm.checkinModel?.pickup?.type + "-" + vm.checkinModel?.pickup?.id,
		dropoffRef: vm =>
			vm.checkinModel?.dropoff?.type + "-" + vm.checkinModel?.dropoff?.id
	},
	activated() {
		this.$refs.form.resetValidation();
	},
	methods: {
		...mapMutations("service", ["SET_REFERENCE", "SET_RECOMMENDED"]),
		selected() {
			if (this.$route.params.id === "dropoff") {
				this.dropoff = JSON.parse(JSON.stringify(this.selection));
				if (this.dropoff.id != this.checkinModel.dropoff.id) {
					this.SET_REFERENCE(null);
					this.SET_RECOMMENDED(false);
					this.flightValidationData = null;
				}
			}

			if (this.$route.params.id === "pickup") {
				this.pickup = JSON.parse(JSON.stringify(this.selection));
				if (this.pickup.id != this.checkinModel.pickup.id) {
					this.SET_REFERENCE(null);
					this.SET_RECOMMENDED(false);
					this.flightValidationData = null;
				}
			}
			this.$router.replace(this.getBasePath("/form/location"));
		},
		t(key, options = {}) {
			return this.$t(`section.pickupDropOff.form.${key}`, options);
		}
	}
};
</script>
